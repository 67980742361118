import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/website/src/components/DocsLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`React Ensemble is written in TypeScript.
Although you can use the library freely with JavaScript, the structure of your application's inputs will be expected to match certain types.
Therefore, referencing the type definitions can be useful even for JavaScript developers.`}</p>
    <p>{`The type definitions in this file are considered part of React Ensemble's public API.`}</p>
    <h2 {...{
      "id": "animation"
    }}>{`Animation`}</h2>
    <p>{`Representation of a React Ensemble animation that can generate state at any point in time.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`interface Animation<State extends object> {
  length: number;
  config: Required<TrackConfig<State>>;
  getFrameState(frame: number): State;
  layers: Record<string, CalculatedTrackRegion<State>[]>;
  activeVars: Set<keyof State>;
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`length`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The length of the animation (in milliseconds).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`config`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Required<TrackConfig<State>>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The complete config that was used to generate the animation. Changing these values has no effect on the animation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`getFrameState`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`(frame: number) => State`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The animation's frame state generator. Returns the current state of the animation at any time value.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`layers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Record<string, CalculatedTrackRegion<State>[]>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The calculated track regions, grouped by layer name, that were generated to create this animation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`activeVars`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Set<keyof State>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The state properties that are animated at any point during the animation.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Generated by `}<a parentName="p" {...{
        "href": "/docs/api/trackutils#gen"
      }}><inlineCode parentName="a">{`TrackUtils.gen`}</inlineCode></a>{`. The function `}<inlineCode parentName="p">{`getFrameState`}</inlineCode>{` is used by `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` to calculate animation state.`}</p>
    <h2 {...{
      "id": "calculatedtrackregion"
    }}>{`CalculatedTrackRegion`}</h2>
    <p>{`A track region that has been parsed into an animation-readable format by `}<inlineCode parentName="p">{`TrackUtils.gen`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`interface CalculatedTrackRegion<State extends object = any> {
  id: string;
  activeVars: Set<keyof State>;
  get: (current: number) => State;
  start: number;
  end: number;
  layer: string;
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The region's unique identifier.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`activeVars`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Set<keyof State>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The state properties that are animated at any point during the region.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`get`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`(current: number) => State`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The region's frame state generator. Returns the current state of the animation at any time value within the region.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`start`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The region's starting point (in milliseconds).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`end`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The region's ending point (in milliseconds).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`layer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The region's layer name.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "controlpanelprops"
    }}>{`ControlPanelProps`}</h2>
    <p>{`Includes all the data necessary to render a responsive playback control with play/pause, fast-forward, reverse, and progress bar features.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`interface ControlPanelProps {
  tick: number;
  length: number | null;
  playing: boolean;
  direction: Direction;
  setTick: (tick: number) => void;
  play: () => void;
  pause: () => void;
  fastForward: () => void;
  reverse: () => void;
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`tick`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The track's time position (in milliseconds).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`length`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode>{` or `}<inlineCode parentName="td">{`null`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The length of the animation (in milliseconds).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`playing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether the animation is playing.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`direction`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#direction"
            }}><inlineCode parentName="a">{`Direction`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The mode of playback.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`setTick`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`(tick: number) => void`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback to signal a manual tick change.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`play`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`() => void`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback to signal a manual play.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pause`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`() => void`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback to signal a manual pause.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fastForward`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`() => void`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback to signal a manual fast-forward toggle (on or off).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`reverse`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`() => void`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback to signal a manual reverse toggle (on or off).`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Used primarily by `}<inlineCode parentName="p">{`Controller`}</inlineCode>{` to communicate with its `}<inlineCode parentName="p">{`panel`}</inlineCode>{` prop, which is `}<inlineCode parentName="p">{`SimpleControlPanel`}</inlineCode>{` by default.`}</p>
    <h2 {...{
      "id": "direction"
    }}>{`Direction`}</h2>
    <p>{`Enumeration of all possible playback modes in a `}<inlineCode parentName="p">{`Controller`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`enum Direction {
  Normal,
  FastForward,
  Reverse
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Direction.Normal`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The animation is playing forwards at normal speed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Direction.FastForward`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The animation is playing forwards at a faster speed. The exact rate is configured by `}<inlineCode parentName="td">{`Controller`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Direction.Reverse`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The animation is playing backwards, sometimes at a faster speed. The exact rate is configured by `}<inlineCode parentName="td">{`Controller`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "easingfunction"
    }}>{`EasingFunction`}</h2>
    <p>{`A function that will transform a normalized time `}<inlineCode parentName="p">{`[0,1]`}</inlineCode>{` according to some curve.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`type EasingFunction = (progress: number) => number;
`}</code></pre>
    <h2 {...{
      "id": "interpolationfunction"
    }}>{`InterpolationFunction`}</h2>
    <p>{`A function that, based on two values, creates a function that will blend between the two values over a normalized time range `}<inlineCode parentName="p">{`[0,1]`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`type InterpolationFunction = <T>(start: T, end: T) => (progress: number) => T;
`}</code></pre>
    <h2 {...{
      "id": "loadevent"
    }}>{`LoadEvent`}</h2>
    <p>{`Event payload returned by `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` when it finishes initializing the animation.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`interface LoadEvent<State extends object = any> {
  animation: Animation<State>;
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`animation`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#animation"
            }}><inlineCode parentName="a">{`Animation<State>`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The generated animation.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "loopconfig"
    }}>{`LoopConfig`}</h2>
    <p>{`Configuration for if and how a track region should loop during an animation.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`interface LoopConfig {
  boomerang?: boolean;
  count?: number;
  until?: number;
  duration?: number;
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`boomerang`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether the region should "boomerang" back to its initial values after each execution. (Optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`count`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of times the region should loop in addition to its regular execution. (Optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`until`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The absolute time in milliseconds the region should loop until. (Optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The number of milliseconds the region should loop for. (Optional)`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`If used for an animation parsed by `}<inlineCode parentName="p">{`TrackUtils.gen`}</inlineCode>{`, the following rules apply:`}</p>
    <ul>
      <li parentName="ul">{`Zero or one of the properties `}<inlineCode parentName="li">{`count`}</inlineCode>{`, `}<inlineCode parentName="li">{`until`}</inlineCode>{`, and `}<inlineCode parentName="li">{`duration`}</inlineCode>{` may be defined, but no more.`}</li>
      <li parentName="ul">{`If none of the properties `}<inlineCode parentName="li">{`count`}</inlineCode>{`, `}<inlineCode parentName="li">{`until`}</inlineCode>{`, or `}<inlineCode parentName="li">{`duration`}</inlineCode>{` are defined, the region will be a `}<a parentName="li" {...{
          "href": "/docs/guides/looping#passive-loops"
        }}>{`passive loop`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "regionstate"
    }}>{`RegionState`}</h2>
    <p>{`Helper type that maps every property of an animation's state to a declarative `}<a parentName="p" {...{
        "href": "#regionstateproperty"
      }}><inlineCode parentName="a">{`RegionStateProperty`}</inlineCode></a>{` for use in a `}<a parentName="p" {...{
        "href": "#trackregionatom"
      }}><inlineCode parentName="a">{`TrackRegionAtom`}</inlineCode></a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`type RegionState<V> = { [K in keyof V]?: RegionStateProperty<V[K]> };
`}</code></pre>
    <p>{`For example, this object would work for `}<inlineCode parentName="p">{`RegionState<{ x: number, y: number }>`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`{
  x: { to: 10 },
  y: { from: 3, to: 8 }
}
`}</code></pre>
    <h2 {...{
      "id": "regionstateproperty"
    }}>{`RegionStateProperty`}</h2>
    <p>{`Description of how an animation's state property should change over the course of a single track region.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`type RegionStateProperty<T> = {
  from?: T;
  to?: T;
  set?: T | ((previous: T) => T);
};
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`from`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`T`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If specified, the state property will be set to this value at the start of the region.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`to`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`T`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If specified, the state property will gradually change to this value over the duration of the region.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`set`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`T`}</inlineCode>{` or `}<inlineCode parentName="td">{`(previous: T) => T`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If specified, the state property will be set to this value (or the output of this function when given the previous value) at the start of the region.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`If used for an animation parsed by `}<inlineCode parentName="p">{`TrackUtils.gen`}</inlineCode>{`, the following rules apply:`}</p>
    <ul>
      <li parentName="ul">{`At least `}<inlineCode parentName="li">{`to`}</inlineCode>{` or `}<inlineCode parentName="li">{`set`}</inlineCode>{` must be defined.`}</li>
      <li parentName="ul">{`If `}<inlineCode parentName="li">{`set`}</inlineCode>{` is defined, `}<inlineCode parentName="li">{`from`}</inlineCode>{` and `}<inlineCode parentName="li">{`to`}</inlineCode>{` must not be defined.`}</li>
    </ul>
    <h2 {...{
      "id": "resolverlayerdata"
    }}>{`ResolverLayerData`}</h2>
    <p>{`Used within a `}<inlineCode parentName="p">{`TrackLayerResolver`}</inlineCode>{`, contains metadata about any region across any layer that is animating the state property at this point.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`interface ResolverLayerData<T> {
  name: string;
  rank: number;
  age: number;
  value: T;
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The region's layer name.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`rank`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The region's layer rank, calculated by alphanumerically sorting all the layer names in the track. A higher rank means a higher position in that list.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`age`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The milliseconds that have passed since this state property was changed in this layer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`T`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The value of this state property in this layer at this time.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "tickevent"
    }}>{`TickEvent`}</h2>
    <p>{`Event payload returned by `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` on each tick.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`interface TickEvent {
  value: number;
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The track's time position (in milliseconds).`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "timelineendbehavior"
    }}>{`TimelineEndBehavior`}</h2>
    <p>{`Describes how the engine will calculate frame states for time values greater than the length of the animation.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`type TimelineEndBehavior = "stop" | "continue" | "loop" | "boomerang";
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"stop"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time will be clamped to the length of the animation. Gives the impression of the animation pausing once it is finished.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"continue"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time will continue to increase. Passive loops will continue to run indefinitely.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"loop"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time will reset back to the start of the animation. Gives the impression of the animation looping.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"boomerang"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time will be transformed so the animation appears to run backwards after it completes, then start over.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "trackconfig"
    }}>{`TrackConfig`}</h2>
    <p>{`Top-level configuration for an animation.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`interface TrackConfig<State extends object = any> {
  endBehavior?: TimelineEndBehavior;
  easing?: EasingFunction;
  interp?: InterpolationFunction;
  resolver?: TrackLayerResolver<State>;
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`endBehavior`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#timelineendbehavior"
            }}><inlineCode parentName="a">{`TimelineEndBehavior`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`How the engine will calculate frame states for time values greater than the length of the animation. (Optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`easing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#easingfunction"
            }}><inlineCode parentName="a">{`EasingFunction`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The easing function that will be applied to each region if it does not specify its own. (Optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`interp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#interpolationfunction"
            }}><inlineCode parentName="a">{`InterpolationFunction`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The interpolation function that will be applied to each region if it does not specify its own. (Optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`resolver`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#tracklayerresolver"
            }}><inlineCode parentName="a">{`TrackLayerResolver`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The function that will be used to determine which state value to use when multiple layers animate the same one. (Optional)`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "tracklayerresolver"
    }}>{`TrackLayerResolver`}</h2>
    <p>{`Function used inside `}<inlineCode parentName="p">{`Animation.getFrameState`}</inlineCode>{` that will determine the value for a state property animated by multiple layers at once.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`type TrackLayerResolver<State extends object = any> = (
  stateKey: keyof State,
  layers: ResolverLayerData<State[keyof State]>[]
) => State[keyof State];
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`stateKey`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`keyof State`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The key of the state property in question.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`layers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#resolverlayerdata"
            }}><inlineCode parentName="a">{`ResolverLayerData`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Metadata about any region across any layer that is animating the state property at this point.`}</td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li parentName="ul">{`Returns: The composite value to be used for that state property.`}</li>
    </ul>
    <h2 {...{
      "id": "trackregion"
    }}>{`TrackRegion`}</h2>
    <p>{`Data used to describe an animation's behavior over a given time frame.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`type TrackRegion<State extends object = any> =
  | TrackRegionAtom<State>
  | TrackRegionGroup<State>;
`}</code></pre>
    <p><inlineCode parentName="p">{`TrackRegion`}</inlineCode>{` is actually a union of two types of track regions: `}<a parentName="p" {...{
        "href": "#trackregionatom"
      }}><inlineCode parentName="a">{`TrackRegionAtom`}</inlineCode></a>{` and `}<a parentName="p" {...{
        "href": "#trackregiongroup"
      }}><inlineCode parentName="a">{`TrackRegionGroup`}</inlineCode></a>{`.
Wherever track regions are expected, there can be any assortment of these two region types.`}</p>
    <p>{`Although `}<inlineCode parentName="p">{`TrackRegionAtom`}</inlineCode>{` and `}<inlineCode parentName="p">{`TrackRegionGroup`}</inlineCode>{` have some unique properties, they have many in common.
The properties shared across all types of track regions include:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`start`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The region's starting point, in milliseconds. (Optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`end`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The region's ending point, in milliseconds. (Optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`easing`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#easingfunction"
            }}><inlineCode parentName="a">{`EasingFunction`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The region's easing function, which will override the `}<inlineCode parentName="td">{`TrackConfig`}</inlineCode>{` easing function. (Optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`interp`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#interpolationfunction"
            }}><inlineCode parentName="a">{`InterpolationFunction`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The region's interpolation function, which will override the `}<inlineCode parentName="td">{`TrackConfig`}</inlineCode>{` interpolation function. (Optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`layer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The region's layer name. (Optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`loop`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`LoopConfig`}</inlineCode>{` or `}<inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Determines if and how the region should loop within the animation. (Optional) If true, will be a passive loop. If an object, will be parsed according to `}<a parentName="td" {...{
              "href": "#loopconfig"
            }}><inlineCode parentName="a">{`LoopConfig`}</inlineCode></a></td>
        </tr>
      </tbody>
    </table>
    <p>{`If used for an animation parsed by `}<inlineCode parentName="p">{`TrackUtils.gen`}</inlineCode>{`, the following rules apply:`}</p>
    <ul>
      <li parentName="ul">{`If defined, `}<inlineCode parentName="li">{`start`}</inlineCode>{` must be later than or equal to the end of the region before it in the same layer.`}</li>
      <li parentName="ul">{`If defined, `}<inlineCode parentName="li">{`end`}</inlineCode>{` must be later than or equal to `}<inlineCode parentName="li">{`start`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`If `}<inlineCode parentName="li">{`loop`}</inlineCode>{` is defined, `}<inlineCode parentName="li">{`end`}</inlineCode>{` must not be defined.`}</li>
    </ul>
    <h2 {...{
      "id": "trackregionatom"
    }}>{`TrackRegionAtom`}</h2>
    <p>{`A `}<inlineCode parentName="p">{`TrackRegion`}</inlineCode>{` that describes a single "curve" of an animation, which involves zero or more state properties changing in a particular way over a defined time frame.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`interface TrackRegionAtom<State extends object = any> extends TrackRegionBase {
  duration?: number;
  state?: RegionState<State>;
}
`}</code></pre>
    <ul>
      <li parentName="ul">{`Includes all the common `}<a parentName="li" {...{
          "href": "#trackregion"
        }}><inlineCode parentName="a">{`TrackRegion`}</inlineCode></a>{` properties.`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`duration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The region's length, in milliseconds. (Optional)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`state`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#regionstate"
            }}><inlineCode parentName="a">{`RegionState<State>`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Description of any state property changes over the course of the region. (Optional)`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`If used for an animation parsed by `}<inlineCode parentName="p">{`TrackUtils.gen`}</inlineCode>{`, the following rules apply:`}</p>
    <ul>
      <li parentName="ul">{`If `}<inlineCode parentName="li">{`duration`}</inlineCode>{` and `}<inlineCode parentName="li">{`end`}</inlineCode>{` are defined, `}<inlineCode parentName="li">{`end`}</inlineCode>{` must equal `}<inlineCode parentName="li">{`duration`}</inlineCode>{` plus the region before it in the same layer's end time.`}</li>
      <li parentName="ul">{`If `}<inlineCode parentName="li">{`loop`}</inlineCode>{` is defined, `}<inlineCode parentName="li">{`duration`}</inlineCode>{` must be defined.`}</li>
    </ul>
    <h2 {...{
      "id": "trackregiongroup"
    }}>{`TrackRegionGroup`}</h2>
    <p>{`A group of track regions that should be treated as a single region.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`interface TrackRegionGroup<State extends object = any> extends TrackRegionBase {
  regions: TrackRegion<State>[];
  relativeTime?: boolean;
}
`}</code></pre>
    <ul>
      <li parentName="ul">{`Includes all the common `}<a parentName="li" {...{
          "href": "#trackregion"
        }}><inlineCode parentName="a">{`TrackRegion`}</inlineCode></a>{` properties.`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`regions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`TrackRegion[]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The array of regions to be grouped and considered as a single region.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`relativeTime`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether the timestamps of all regions in the group will be parsed as if this group's starting time equals zero. Default: `}<inlineCode parentName="td">{`false`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <p>{`When parsed by `}<inlineCode parentName="p">{`TrackUtils.gen`}</inlineCode>{`, every group becomes its own self-contained animation. This has the following effects:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`relativeTime`}</inlineCode>{` will determine whether every region's timestamps are relative to the start of the group. If true, `}<inlineCode parentName="li">{`start: 100`}</inlineCode>{` would be interpreted as `}<inlineCode parentName="li">{`start: (group_start) + 100`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Layers will be namespaced according to this pattern: `}<inlineCode parentName="li">{`"parent-layer-name.layer-name"`}</inlineCode>{`. Therefore, a region with the layer `}<inlineCode parentName="li">{`"foo"`}</inlineCode>{` in a group will not be in the same layer as `}<inlineCode parentName="li">{`"foo"`}</inlineCode>{` at the top level.`}</li>
    </ul>
    <h2 {...{
      "id": "updateevent"
    }}>{`UpdateEvent`}</h2>
    <p>{`Event payload returned by `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{` every time its time value updates.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`interface UpdateEvent<State extends object = any> {
  state: State;
}
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`state`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`State`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The animation's frame state at the time the event was generated.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      